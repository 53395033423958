import * as React from 'react';
import { graphql } from 'gatsby';
import SiteWrapper from '../components/siteWrapper';
import Abteilung from '../components/abteilung';
import { AdditionalInfo } from '../components/abteilung/additionalInfo';

const IndexPage = (props) => {
    const edges = props.data.allFile.edges;
    const additionalInfo = props.data.fussballAdditional.childMarkdownRemark;

    // Check if there is any additional information (html content or files array is not empty)
    const hasAdditionalInfo =
        additionalInfo &&
        ((additionalInfo.html && additionalInfo.html !== '') ||
            (additionalInfo.frontmatter.files &&
                additionalInfo.frontmatter.files.length > 0));

    return (
        <SiteWrapper
            title="Fussball - SV Kappel"
            description="In unserer Fußballabteilung haben wir platz für jeden. Von den Kindern über die Junior:innen bis zu den alten Herren ist alles vertreten. Unsere aktive Mannschaft spielt in der Kreisliga A."
        >
            <Abteilung edges={edges} />
            {hasAdditionalInfo && (
                <AdditionalInfo additionalInfo={additionalInfo} />
            )}
        </SiteWrapper>
    );
};

export default IndexPage;

export const query = graphql`
    query FussballQuery {
        allFile(
            filter: { sourceInstanceName: { eq: "fussball" } }
            sort: { fields: childMarkdownRemark___frontmatter___order }
        ) {
            edges {
                node {
                    childMarkdownRemark {
                        frontmatter {
                            title
                            mannschaften {
                                beschreibung
                                beschreibungMd
                                bild {
                                    childImageSharp {
                                        gatsbyImageData(
                                            width: 400
                                            aspectRatio: 1.833
                                        )
                                    }
                                }
                                kontaktperson
                                ort
                                title
                                trainingszeiten
                            }
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
        }
        fussballAdditional: file(base: { eq: "fussballAdditional.md" }) {
            childMarkdownRemark {
                html
                frontmatter {
                    files {
                        anzeigename
                        file {
                            publicURL
                        }
                    }
                }
            }
        }
    }
`;
